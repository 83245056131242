import React from 'react';

const CustomToast = ({ title, message, isPositive }) => {
  return (
    <div className="row">
      <div className="col-3">
        <div className="custom-toast-container-image">
          {isPositive ? (
            <img src="../../assets/images/positive-alert.png" className="custom-toast-image" alt="Positive Alert"></img>
          ) : (
            <img src="../../assets/images/negative-alert.png" className="custom-toast-image" alt="Negative Alert"></img>
          )}
        </div>
      </div>
      <div className="col">
        <div className="custom-toast-container-text">
          <div className="custom-toast-title">
            {title}
          </div>
          <div className="custom-toast-message">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomToast;