import React from 'react';
import FeatherIcon from 'feather-icons-react';
import '../styles/ModalSlideFromBottom.css';

const ModalSlideFromBottom = ({ isOpen, onClose, children }) => {
  const modalClass = isOpen ? 'modal-slide-from-bottom open' : 'modal-slide-from-bottom';

  return (
    <div className={modalClass}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
            <FeatherIcon className="custom-button-icon" icon="x-circle" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default ModalSlideFromBottom;
