import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './styles/App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import settings from './settings';

//import jwt from 'jsonwebtoken';

//const token = jwt.sign({}, 'votre_secret_key');

//localStorage.setItem('token', token);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={settings.reCAPTCHA.publicKey}>
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
