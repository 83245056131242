import React from 'react';
import '../styles/Button.css';
import FeatherIcon from 'feather-icons-react';

const Button = ({ text, onClick, icon = null }) => {
  return (
    <button className="custom-button" onClick={onClick}>
      {icon && <FeatherIcon className="custom-button-icon" icon={icon} />} {text}
    </button>
  );
};

export default Button;