import React, { useState, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import axios from 'axios';

import LoadingPage from '../custom/Loading';
import Button from '../custom/Button';
import CustomToast from '../custom/Toast';
import ModalSlideFromBottom from '../custom/ModalSlideFromBottom';

import settings from '../settings';

import '../styles/Custom.css';

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [dots, setDots] = useState('...');
  const [isHovered, setIsHovered] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [email, setEmail] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  //const token = localStorage.getItem('token');
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  //toast(token);

  // Fonction pour gérer le hover sur le logo
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Effet pour simuler le chargement de la page
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Effet pour détecter la fin du chargement de la page
  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoading(false);
    });

    return () => {
      window.removeEventListener('load', () => {});
    };
  }, []);

  // Fonction pour gérer le clic sur le bouton
  const handleButtonClick = async () => {

    setIsLoadingButton(true);

    if(email === ''){
        setInputError(true);
    } else {
        setInputError(false);
    }

    if (!executeRecaptcha) {
      return;
    }

    const recaptchaToken = await executeRecaptcha('REGISTRATION_OPENING');
    // Vous pouvez maintenant envoyer ce jeton à votre serveur et le vérifier

    const dataForm = new URLSearchParams();
    dataForm.append('email', email);
    dataForm.append('recaptchaToken', recaptchaToken);

    try {
      const response = await axios.post(`${settings.backend.apiUrl}/newsletters/add`, dataForm, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
        },
      });

      const data = response.data;

      if (response.status === 200) {
        setInputError(false);
        toast(
          <CustomToast title="Ouïiïi !" message={data.message} isPositive={true} />,
          {
            className: 'custom-toast-success',
            closeButton: false, // Optionnel : pour masquer le bouton de fermeture
            hideProgressBar: true,
            icon: false,
            autoClose: 6000 // Optionnel : Durée d'affichage du toast en millisecondes
          }
        );
      }
    } catch (error) {
      setInputError(true);
      if (error.message === "Network Error") {
        toast(
          <CustomToast title="Oups !" message="Erreur de réseau. Veuillez vérifier votre connexion Internet." isPositive={false} />,
          {
            className: 'custom-toast-error',
            closeButton: false, // Optionnel : pour masquer le bouton de fermeture
            hideProgressBar: true,
            icon: false,
            autoClose: 6000 // Optionnel : Durée d'affichage du toast en millisecondes
          }
        );
      } else if (error.response) {
        toast(
          <CustomToast title="Oups !" message={error.response.data.message} isPositive={false} />,
          {
            className: 'custom-toast-error',
            closeButton: false, // Optionnel : pour masquer le bouton de fermeture
            hideProgressBar: true,
            icon: false,
            autoClose: 6000 // Optionnel : Durée d'affichage du toast en millisecondes
          }
        );
      } else {
        toast(
          <CustomToast title="Oups !" message="Erreur inattendue lors de la requête." isPositive={false} />,
          {
            className: 'custom-toast-error',
            closeButton: false, // Optionnel : pour masquer le bouton de fermeture
            hideProgressBar: true,
            icon: false,
            autoClose: 6000 // Optionnel : Durée d'affichage du toast en millisecondes
          }
        );
      }
    } finally {
      setIsLoadingButton(false);
    }
  
  };

  return (
    <div className="cover-container d-flex h-100 p-3 mx-auto flex-column">
      {isLoading ? (
        <LoadingPage />
      ) : (
        <main role="main" className="inner cover">
          <header className="d-flex justify-content-center align-items-center mb-1">
            <div
              className={`logo mt-4 ${isHovered ? 'bubble hovered' : 'bubble'}`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            ></div>
          </header>
          <h1 className="cover-heading custom-title">Patience{dots}</h1>
          <p className="lead custom-subtitle">notre site web est en préparation</p>
          <p>
          <input
            className={`form-control custom-input mt-2 ${inputError ? 'custom-input-error' : ''}`}
            type="email"
            placeholder="cedric.frog@helloedge.fr"
            onChange={(e) => setEmail(e.target.value)}
            />
          </p>
          <p className="lead">
            <Button
              text={
                isLoadingButton ? (
                  <>
                    <span
                      className="spinner-border spinner-border custom-button-loading-icon"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Je souhaite être informé.e !"
                )
              }
              icon={isLoadingButton ? null : "mail"}
              onClick={handleButtonClick}
              disabled={isLoadingButton}
            />
          </p>
          <p className="lead custom-registration-condition">
            *En m'inscrivant pour recevoir des notifications sur l'ouverture de <b>Hello Edge</b>, je consens à être ajouté à notre liste de diffusion pour recevoir nos newsletters.
          </p>
          <div className="container d-flex justify-content-center align-items-center mt-5">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <a href="https://www.instagram.com/helloedge.fr" target="_blank" rel="noreferrer">
                  <img
                    src="../../assets/images/icon-instagram.png"
                    alt="Instagram"
                    style={{ width: '45px', height: '45px' }}
                  />
                </a>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <a href="https://www.linkedin.com/company/helloedge" target="_blank" rel="noreferrer">
                  <img
                    src="../../assets/images/icon-linkedin.png"
                    alt="LinkedIn"
                    style={{ width: '45px', height: '45px' }}
                  />
                </a>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <a href="https://www.tiktok.com/@helloedge.fr" target="_blank" rel="noreferrer">
                  <img
                    src="../../assets/images/icon-tiktok.png"
                    alt="TikTok"
                    style={{ width: '45px', height: '45px' }}
                  />
                </a>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <a href="https://x.com/helloedgefr" target="_blank" rel="noreferrer">
                  <img
                    src="../../assets/images/icon-x.png"
                    alt="X"
                    style={{ width: '45px', height: '45px' }}
                  />
                </a>
              </div>
            </div>
          </div>

          <ModalSlideFromBottom isOpen={isOpen} onClose={closeModal}>

              <div class="row text-left">
                <div class="col-sm">
                  <div className="custom-modal-subtitle">Editeur et hébergeur du site</div>
                  <p className="custom-modal-text mt-2">
                    <b>Nom de l'entreprise : </b> Hello Edge
                  </p>
                  <p className="custom-modal-text">
                    <b>Forme juridique : </b> Société à responsabiltité Limitée (SARL)
                  </p>
                  <p className="custom-modal-text">
                    <b>Adresse du siège social : </b> 56 chemin de Revaison, 69800 Saint-Priest 
                  </p>
                  <p className="custom-modal-text">
                    <b>Capital social : </b> 4 000,00 € 
                  </p>
                  <p className="custom-modal-text">
                    <b>Contact : </b> <a href="mailto:contact@helloedge.fr" className="custom-modal-href">contact@helloedge.fr</a>
                  </p>
                  <p className="custom-modal-text">
                    <b>Nom du directeur ou du co-directeur de la publication : </b> Olivier Houdard & Ninon Mallet
                  </p>
                  <p className="custom-modal-text">
                    <b>Coordonnées de l’hébergeur du site : </b> Amazon Web Service, Inc. Seattle, Washington, États-Unis  
                  </p>

                </div>

              </div>

          </ModalSlideFromBottom>

        </main>
      )}
      <footer className="d-flex justify-content-center align-items-center mastfoot mt-auto">
        <div className="inner custom-copyright">
          <p>Copyright © 2024 Hello Edge · <a href="?legal-notice" onClick={openModal}>Mentions légales</a></p>
        </div>
      </footer>
    </div>
  );
}

export default Home;