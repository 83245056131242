import React from 'react';
import '../styles/Loading.css'; // Créez ce fichier CSS pour styliser votre page de chargement

function LoadingPage() {
  return (
    <div className="loading-container">
      <img src="assets/images/loader.gif" alt="Loading" />
    </div>
  );
}

export default LoadingPage;
