const settings = {
    // Configuration du site
    web: {
      name: 'Hello Edge',
      url: 'https://www.helloedge.fr',
      cdn: 'https://cdn.helloedge.fr'
    },

    // Configuration du backend
    backend: {
      apiUrl: 'https://backend.helloedge.fr/api',
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDUwMjA0ODh9.4ph8EUiMkWxniK2E_zhcIM1inl7ai9OlJi2XnWer12w',
    },

    // Configuration du reCaptcha
    reCAPTCHA: {
        publicKey: '6Le6qWApAAAAAOV6ZRFh2c8RY1cPBsRtvGPIm19h',
    },
};
  
export default settings;