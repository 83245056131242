// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importez le CSS pour react-toastify
import Home from './components/Home';

function App() {
  return (
    <Router>
      <ToastContainer
      position="top-right"  // Personnalisez la position au besoin
      autoClose={5000}      // Personnalisez la durée de fermeture automatique en millisecondes
      hideProgressBar={false}  // Afficher ou masquer la barre de progression
      newestOnTop={false}     // Afficher les notifications les plus récentes en haut
      closeOnClick        // Fermer la notification en cliquant dessus
      rtl={false}          // Support de la lecture de droite à gauche
      pauseOnFocusLoss    // Mettre en pause en cas de perte de focus
      draggable           // Activer le glissement pour fermer
      pauseOnHover        // Mettre en pause en cas de survol
      closeButton={false} // Afficher ou masquer le bouton de fermeture
      />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;